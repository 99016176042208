export const sectionOptions = [
    { label: 'Preliminaries' },
    { label: 'Demolition' },
    { label: 'Groundworks' },
    { label: 'Piling' },
    { label: 'Concrete' },
    { label: 'Formwork' },
    { label: 'Reinforcement' },
    { label: 'Prestressing' },
    { label: 'Precast Concrete' },
    { label: 'Tanking and Waterproofing' },
    { label: 'Masonry' },
    { label: 'Stone Work' },
    { label: 'Structural Steel' },
    { label: 'Metalwork' },
    { label: 'Roofing' },
    { label: 'Facade Systems' },
    { label: 'Windows' },
    { label: 'Glazing' },
    { label: 'Carpentry' },
    { label: 'Partitions' },
    { label: 'Suspended Ceilings' },
    { label: 'Access Floors' },
    { label: 'Doors' },
    { label: 'Hardware' },
    { label: 'Applied Finishes' },
    { label: 'Tiling' },
    { label: 'Slab and Paving' },
    { label: 'Carpet and Floor Finishes' },
    { label: 'Painting' },
    { label: 'Joinery' },
    { label: 'Furniture, Fittings, and Equipment' },
    { label: 'Hydraulics' },
    { label: 'Drainage' },
    { label: 'Electrical Installations' },
    { label: 'Mechanical Installations' },
    { label: 'Tansportation Services' },
    { label: 'Fire Protection' },
    { label: 'Included Sums' }
  ];

  
  export const subjectToFilters = [
    {
      value: 'DA',
      label: 'DA approvals'
    },
    {
      value: 'engineersDrawings',
      label: 'Engineers drawings'
    },
    {
      value: 'councilApprovals',
      label: 'Council approvals'
    },
    {
      value: 'engineeringApproval',
      label: 'Engineering approval'
    },
    {
      value: 'materialPricing',
      label: 'Final material pricing'
    }
  ];
  
  export const markupFilters = [
    {
      value: 'generalMarkup',
      label: 'General Markup',
      name: 'general'
    }
    // {
    //   value: 'materialMarkup',
    //   label: 'Material Markup',
    //   name:'material'
    // },
    // {
    //   value: 'labourMarkup',
    //   label: 'Labour Markup',
    //   name:'labour'
    // },
    // {
    //   value: 'plantMarkup',
    //   label: 'Plant/Hire Markup',
    //   name:'other'
    // }
  ];
  
  export const userLevelFields = [
    'showDates',
    'showLineItemPricing',
    'showQuoteStatus',
    'showMarkup',
    'showInclusions',
    'includeGST',
    'applyMarkup',
    'markup',
    'coverletter',
    'tcs',
    'footer'
  ];