import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
//import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import GoogleTagManager from 'src/components/GoogleTagManager';
import SettingsNotification from 'src/components/SettingsNotification';
import { AuthProvider } from 'src/contexts/FirebaseAuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { Routes } from 'src/routes';
import useScript from 'src/hooks/hotjar';
import useZohoScript from 'src/hooks/zoho';




const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
  const { settings } = useSettings();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      console.log('Stand alone');
    }
    if (!window.matchMedia('(display-mode: standalone)').matches) {
      console.log('Not Stand alone');
    }
  }, []);

/*  useZohoScript(
    'https://cdn-au.pagesense.io/js/quotingcentral/9e27b870a09b452a89f341c09232286e.js'
  );*/
  
  useZohoScript(
    'https://cdn-au.pagesense.io/js/quotingcentral/dda39d095868495dac6c61c71348ea12.js'
  );


  


  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            dense
            maxSnack={3}
          >
            <Router history={history}>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                <GoogleTagManager />
                <GoogleAnalytics />
                {/* <CookiesNotification /> */}
                {/*<SettingsNotification /> */}
                <Routes routes={routes} />
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
