import React from 'react';

const Logo = (props) => {
  return (
    
    <div className="navbar-brand" href="/" >
      
      <img src="/static/qc-logo.png" 
      alt="QuotingCentral"
      {...props}
      
      /> 
    </div>
    
    
  );
}

export default Logo;
