import { firestore } from 'src/firebase';

export const createActivity = async data => {
  data['date'] = new Date();
  return await firestore
    .collection('activities')
    .add(data)
    .then(async result => {
      await firestore
        .collection('activities')
        .doc(`${result.id}`)
        .update({
          id: result.id
        });
    })
    .catch(err => {
      console.log(`Failed to create activity. ${err}`);
    });
};

