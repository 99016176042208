import { uid } from 'src/hooks/utils';

export const getDefaultTask = function(
  priority = 0,
  section = 0,
  sectionName = ''
) {
  return {
    priority: priority,
    section: section,
    sectionName: sectionName,
    id: uid(),
    data: {}
  };
};
