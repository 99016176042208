import { firestore } from 'src/firebase';
import { getLogo } from 'src/hooks/logo';
import { userLevelFields } from 'src/hooks/quote/constants';
import { getUserDocument } from 'src/hooks/user';
import { getJob } from 'src/hooks/jobs';
import { updateUserDocument } from 'src/hooks/user';
import { getDefaultTask } from 'src/hooks/quote/tasks/defaultTask';

export const createNewQuote = async data => {
  const { defaultQuoteData, quoteNumber } = await getDefaultQuote(data);
  console.log('Default quote details', defaultQuoteData);
  updateUserLevelLastQuoteNumber({
    uid: data.uid,
    lastQuoteNumber: quoteNumber
  });
  return await firestore
    .collection('users')
    .doc(`${data.uid}`)
    .collection('jobs')
    .doc(data.jobId)
    .collection('quotes')
    .add(defaultQuoteData)
    .then(async result => {
      await firestore
        .collection('users')
        .doc(`${data.uid}`)
        .collection('jobs')
        .doc(data.jobId)
        .collection('quotes')
        .doc(`${result.id}`)
        .update({
          quoteID: result.id
        });
      return result.id;
    })
    .catch(err => {
      console.log(`Failed to create quote. ${err}`);
      return false;
    });
};

export const updateQuote = async data => {
  data['lastModifiedBy'] = data.userId;
  data['lastModifiedDate'] = new Date().getTime();

  return await firestore
    .collection('users')
    .doc(`${data.userId}`)
    .collection('jobs')
    .doc(`${data.jobId}`)
    .collection('quotes')
    .doc(`${data.quoteId}`)
    .update(data)
    .then(() => {
      console.log(
        `Success! Quote ${data.quoteId} has been modified by user ${data.userId}`
      );
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
};

export const archiveQuotes = async data => {
  try {
    //1. Get the quote document reference
    const quoteRef = firestore
      .collection('users')
      .doc(`${data.uid}`)
      .collection('jobs')
      .doc(`${data.jobId}`)
      .collection('quotes')
      .doc(`${data.quoteId}`);
    quoteRef.onSnapshot(async doc => {
      if (doc.exists) {
        const batch = firestore.batch();
        const archivedQuoteRef = firestore
          .collection('users')
          .doc(`${data.uid}`)
          .collection('jobs')
          .doc(`${data.jobId}`)
          .collection('archived_quotes')
          .doc(`${data.quoteId}`);
        //2.Copy quote field data to new location
        batch.set(archivedQuoteRef, doc.data());
        //3. Delete quote document
        batch.delete(quoteRef);
        //4. Finally, commit database changes
        batch.commit();
      }
    });
    return true;
  } catch (err) {
    console.log(`Failed to archive quote. ${err}`);
    return false;
  }
};

export const saveQuoteFirebase = async data => {
  // console.log(data);
  // data['lastModifiedBy'] = data.uid;
  // data['lastModifiedDate'] = new Date();
  // delete data.files;
  return await firestore
    .collection('users')
    .doc(`${data.uid}`)
    .collection('jobs')
    .doc(data.jobId)
    .collection('quotes')
    .doc(data.quoteID)
    .update(data)
    .then(() => {
      return true;
    })
    .catch(err => {
      console.log(`Failed to update quote. ${err}`);
      return false;
    });
};

export const getQuote = async data => {
  try {
    const docRef = firestore
      .collection('users')
      .doc(data.uid)
      .collection('jobs')
      .doc(data.jobId)
      .collection('quotes')
      .doc(data.quoteId);

      const companyDataRef = firestore
      .collection('users')
      .doc(data.uid)
      let companyData 
      let quoteNumber = await getNextQuoteNumber(data.uid)
      const asd  = await companyDataRef.get().then(doc=>{
        if (doc.exists) {
           const docData = doc.data()
           companyData = {quoteNumber:quoteNumber,...docData};
        }
      })
      return await docRef.get().then(doc => {
        if (doc.exists) {
          const documentData = doc.data();
          const finalData = {...documentData,companyData:{...companyData},}
          console.log(finalData)
        return finalData;
      }
      return null;
    });
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getDefaultQuote = async data => {
  var quoteNumber = await getNextQuoteNumber(data.uid);
  const URL = await getLogo(data.uid);
  var date = new Date(); // Now
  var newQuote = {
    createdBy: data.uid,
    createdDate: new Date(),
    expirationDate: date.getDate() + 30,
    lastModifiedBy: data.uid,
    lastModifiedDate: new Date().getTime(),
    quoteStatus_t: 'Draft',
    uid: data.uid,
    jobId: data.jobId,
    tasks: data.tasks,
    quoteName_t: `Quote #${quoteNumber}`,
    logoURL: URL,
    tasks: [getDefaultTask()],
    firebase: true
  };
  const userDoc = await getUserDocument({ uid: data.uid });
  const jobDoc = await getJob({
    uid: data.uid,
    jobId: data.jobID
  });

  var config = userDoc && userDoc.quoteConfig ? userDoc.quoteConfig : {};

  userLevelFields.forEach(field => {
    newQuote[field] = config[field] || null;
  });

  newQuote['jobDesc'] = jobDoc && jobDoc.jobDesc ? jobDoc.description : '';
  return { defaultQuoteData: newQuote, quoteNumber };
};

const getNextQuoteNumber = async uid => {
  const userDoc = await getUserDocument({ uid });
  var nextQuoteNumber = 1;
  if (userDoc && userDoc.lastQuoteNumber)
    nextQuoteNumber = parseInt(userDoc.lastQuoteNumber) + 1;
  return nextQuoteNumber;
};

//records the number for the latest quote created (quote number)
export const updateUserLevelLastQuoteNumber = async data => {
  updateUserDocument({ uid: data.uid, lastQuoteNumber: data.lastQuoteNumber });
};
