export const isFileIncludedInQuote = (fileName, list) => {
  if (!fileName || !list) return false;

  var exists = list.includes(fileName);

  return exists;
};

export const checkFilesIncludedInQuote = (files, list) => {
  if (!files) return {};
  console.log(files);
  if (!list) {
    files.map(file => (file['checked'] = false));
  } else {
    files.map(
      file => (file['checked'] = isFileIncludedInQuote(file.name, list))
    );
  }
  console.log('Files modified', files);
  return files;
};

export const getIncludedFilesName = files => {
  var fileNames = [];
  if (files)
    files.forEach(file => {
      if (file.checked && file.name) fileNames.push(file.name);
    });
  return fileNames;
};
