import { functions } from 'src/firebase';

export const sendPasswordResetEmail = async data => {
  const customPasswordResetEmail = functions.httpsCallable(
    'customPasswordResetEmail'
  );
  return customPasswordResetEmail({
    email: data.email,
    emailTemplate:'PasswordReset'
  }).then(result => {
    console.log(result);
    return result;
  });
};

export const sendPasswordResetConfirmationEmail = async data => {
  console.log("In password Reset Email")
  const customPasswordResetEmail = functions.httpsCallable(
    'customPasswordResetEmail'
  );
  return customPasswordResetEmail({
    email: data.email,
    emailTemplate:'PasswordResetConfirmation'
  }).then(result => {
    console.log(result);
    return result;
  });
};

export const sendVerificationEmail = async data => {
  console.log('Triggered sendVerficationEmail in hooks, ', data);
  const customVerificationEmail = functions.httpsCallable(
    'customVerificationEmail'
  );
  return customVerificationEmail({
    email: data.email,
    emailTemplate:'EmailVerification'
  }).then(result => {
    console.log(result);
    return result;
  });
};

export const sendSignUpVerificationEmail = async data => {
    console.log('Triggered sendSignUpVerificationEmail in hooks, ', data);
    const customVerificationEmail = functions.httpsCallable(
      'customVerificationEmail'
    );
    return customVerificationEmail({
      email: data.email,
      emailTemplate:'EmailSignUpVerification'
    }).then(result => {
      console.log(result);
      return result;
    });
  };

  export const sendEmailAdressChangeToNewEmail = async data => {
    console.log('Triggered sendSignUpVerificationEmail in hooks, ', data);
    const customVerificationEmail = functions.httpsCallable(
      'customVerificationEmail'
    );
    return customVerificationEmail({
      email: data.email,
      emailTemplate:'EmailAdressChangeToNewEmail'
    }).then(result => {
      console.log(result);
      return result;
    });
  };
