import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  //Link,
  //SvgIcon,
  Grid,
  Container,
  Fab,
  alpha,
} from '@material-ui/core';
//import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
/*import Contacts from './Contacts';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';*/
import UnverifiedEmail from './UnverifiedEmail';
import { List, ListItem, ListItemText } from '@material-ui/core';
//import SearchIcon from '@material-ui/icons/Search';
//import InputBase from '@material-ui/core/InputBase';
import { KeyboardArrowUp } from '@material-ui/icons';
import HideOnScroll from './HideOnScroll';
import SideDrawer from './SideDrawer';
import BackToTop from './BackToTop';
import useAuth from 'src/hooks/useAuth';


const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    background: 'white',
    color: 'black'
  },
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
    background: 'white'
  },
  linkText: {
    //textDecoration: `none`,
    //textTransform: `uppercase`,
    color: `#757575`
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#000000', 0.15),
    '&:hover': {
      backgroundColor: alpha('#000000', 0.25)
    },
    marginLeft: 0,
    height: '40px',
    marginTop: '15px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  nameDropDown: {
    padding: '5px',
    height: '45px'
  },
  nameDropDownContainer: {
    width: '340px'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  },
  navbarDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`
  },
  navListDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`
  },
  activeNav: {
    fontWeight: 'bold',
    borderBottom: '2px solid black',
    color: '#000000'
  }
}));

const navLinks = [
  { title: `Dashboard`, path: `/app/reports/dashboard` },
  { title: `My Jobs`, path: `/app/jobs/browse/kanban-view` },
  { title: `Calendar`, path: `/app/calendar` },
  { title: `My Customers`, path: `/app/management/customers` },
  { title: `Admin`, path: `/app/management/users`, admin:true }
];

const sideBarNavLinks = [
  { title: `Dashboard`, path: `/app/reports/dashboard` },
  { title: `My Jobs`, path: `/app/jobs/browse/kanban-view` },
  { title: `Calendar`, path: `/app/calendar` },
  { title: `My Customers`, path: `/app/management/customers` },
  { title: `Admin`, path: `/app/management/users`, admin:true },
  { title: `Account`, path: `/app/account`},
  { title: `Logout`, logout:true },
];

const TopBar = ({
  className,
  onMobileNavOpen,
  emailVerified,
  setEmailVerified,
  ...rest
}) => {
  const classes = useStyles();
  const { user } = useAuth();


  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
     

      <HideOnScroll>
        <AppBar position="fixed">
          <Toolbar component="nav" className={classes.toolbar}>
            <Container maxWidth="xl" className={classes.navbarDisplayFlex}>
              <Grid>
                <IconButton edge="start" aria-label="home">
                  <RouterLink to="/">
                    <Logo />
                  </RouterLink>
                </IconButton>
              </Grid>
              <Hidden smDown>
                <Grid container justifyContent="flex-start" alignItems="center">
                  <List
                    component="nav"
                    aria-labelledby="main navigation"
                    className={classes.navListDisplayFlex}
                  >
                    {navLinks.map(({ title, path, admin }) => (
                      (!admin ||  admin === user.isAdmin) &&
                      <NavLink
                        variant="body1"
                        color="inherit"
                        className={classes.linkText}
                        activeClassName={classes.activeNav}
                        to={path}
                        key={title}
                      >
                        <ListItem button>
                          <ListItemText primary={title} />
                        </ListItem>
                      </NavLink>

                      
                    ))}
                      <NavLink
                        variant="body1"
                        color="inherit"
                        className={classes.linkText}
                        to='#'
                        key='Help'
                        onClick={() => $zoho.salesiq.floatwindow.visible("show")}
                      >
                      <ListItem button>
                          <ListItemText primary='Help' />
                        </ListItem>
                        </NavLink>
                  
                  </List>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  className={classes.nameDropDownContainer}
                >
                  <Box ml={2} className={classes.nameDropDown}>
                    <Account />
                  </Box>
                </Grid>
              </Hidden>

              <Hidden mdUp>
                <SideDrawer navLinks={sideBarNavLinks} />
              </Hidden>
            </Container>
          </Toolbar>
          {!emailVerified && <UnverifiedEmail setEmailVerified={setEmailVerified}/>}

        </AppBar>
      </HideOnScroll>
      <Toolbar id="back-to-top-anchor" />
     

      <BackToTop>
        <Fab color="secondary" size="large" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </BackToTop>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
