import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
//import NavBar from './NavBar';
import TopBar from './TopBar';
//import BottomBar from './BottomBar';
//import { useLocation } from 'react-router-dom';
import firebase from 'firebase/app';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: 10
    // }
  },
  unverifiedEmail: {
    paddingTop: 87,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 160
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ path, children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
 // let location = useLocation();
  const [emailVerified, setEmailVerified] = useState(firebase.auth().currentUser? firebase.auth().currentUser.emailVerified : null)

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        emailVerified={emailVerified}
        setEmailVerified={() => setEmailVerified(true)}
        // openMobile={isMobileNavOpen}
        
      />
     {/* <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        emailVerified={emailVerified}
      />*/} 

      {/* the "plus" icon is not displayed when creating new job/quote */}
      {/*!location.pathname.match('/app/job/create') ? <BottomBar /> : ''*/}
      <div
        className={clsx(
          classes.wrapper,
          !emailVerified && classes.unverifiedEmail
        )}
      >
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
