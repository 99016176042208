import { firestore, functions, storage } from 'src/firebase';
import firebase from 'firebase/app';

export const uploadLogo = async data => {
  if (!data || !data.file) return;
  var type = data.file.type.split('/');
  const filePath = `users/${data.userId}/logo/logo.png`;
  const fileRef = storage.ref().child(filePath);

  return await fileRef
    .put(data.file)
    .then(snapshot => {
      return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
    })

    .then(downloadURL => {
      console.log(
        `Successfully uploaded file and got download link - ${downloadURL}`
      );
      return downloadURL;
    })

    .catch(error => {
      // Use to signal error if something goes wrong.
      console.log(`Failed to upload file and get link - ${error}`);
    });
};

export const getLogo = async userId => {
  var result = null;
  try {
    result = await storage
      .ref(`users/${userId}/logo/logo.png`)
      .getDownloadURL();
  } catch (err) {
    console.log('Logo does not exist', err);
  }
  return result;
};
