import { firestore, functions, storage } from 'src/firebase';
import firebase from 'firebase/app';
import { createActivity } from './activity';

export const createJob = async data => {
  data['createdBy'] = data.uid;
  data['createdDate'] = new Date();
  data['lastModifiedBy'] = data.uid;
  data['lastModifiedDate'] = new Date().getTime();
  delete data.files;
  return await firestore
    .collection('users')
    .doc(`${data.uid}`)
    .collection('jobs')
    .add(data)
    .then(async result => {
      await firestore
        .collection('users')
        .doc(`${data.uid}`)
        .collection('jobs')
        .doc(`${result.id}`)
        .update({
          id: result.id
        });
      return result.id;
    })
    .catch(err => {
      console.log(`Failed to create job. ${err}`);
      return false;
    });
  // const createJob = functions.httpsCallable('createJob');
  // console.log("In jobs.js ", data);
  // return createJob(data);
};


//test function to see upload return rate
export const uploadSingleFile = async data => {
  const filePath = `users/${data.userId}/jobs/${data.jobId}/${data.file.name}`;
  const fileRef = storage.ref().child(filePath);

  fileRef.put(data.file).then((snapshot) => {
    console.log('Uploaded a blob or file!');
  });

}

export const uploadFiles = async data => {
  const promises = [];
  console.log(data);
  data.files = Array.from(data.files);
  data.files.forEach(function(file) {
    console.log(file);
    promises.push(
      new Promise((resolve, reject) => {
        const filePath = `users/${data.userId}/jobs/${data.jobId}/${file.name}`;
        const fileRef = storage.ref().child(filePath);

        const uploadTask = fileRef.put(file);

        uploadTask.on(
          'state_changed',
          null,
          error => reject(error),
          () => {
            resolve(uploadTask.snapshot.ref);
            //create activity entry for file upload
            createActivity({
              activity: 'File uploaded',
              uid: data.userId,
              jobId: data.jobId,
              fileName: file.name,
              filePath: filePath
            });
          }
        );
      })
    );
  });
  return Promise.all(promises)
    .then(() => {
      return 'Succcess';
    })
    .catch(error => {
      return error;
    });
};

export const readFiles = async data => {
  const storageRef = storage.ref(`users/${data.userId}/jobs/${data.jobId}`);
  var result = await storageRef.listAll();
  if (result) return result.items;
  return null;
};

export const deleteFile = async data => {
  const filePath = `users/${data.userId}/jobs/${data.jobId}/${data.fileName}`;
  const fileRef = storage.ref().child(filePath);

  // Delete the file
  return fileRef
    .delete()
    .then(result => {
      // File deleted successfully
      console.log('File deleted successfully');
      createActivity({
        activity: 'File deleted',
        uid: data.userId,
        jobId: data.jobId,
        fileName: data.fileName
      });
      return true;
    })
    .catch(error => {
      console.log(`File delete failed ${error}`);
      return false;
    });
};

export const updateAccount = async data => {
  const updateAccount = functions.httpsCallable('updateAccount');
  return updateAccount(data).then(result => {
    console.log(result);
  });
};

export const updateJob = async data => {
  data['lastModifiedBy'] = data.userId;
  data['lastModifiedDate'] = new Date().getTime();

  return await firestore
    .collection('users')
    .doc(`${data.userId}`)
    .collection('jobs')
    .doc(`${data.jobId}`)
    .update(data)
    .then(() => {
      console.log(
        `Success! Job ${data.jobId} has been modified by user ${data.userId}`
      );
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
  // const updateJob = functions.httpsCallable('updateJob');
  // return updateJob(data).then(result => {
  //   return result;
  // });
};

export const addJobToAccount = async data => {
  return await firestore
    .collection('users')
    .doc(`${data.userId}`)
    .collection('accounts')
    .doc(`${data.accountId}`)
    .update({
      jobs: firebase.firestore.FieldValue.arrayUnion(data.jobId)
    })
    .then(() => {
      console.log(
        `Success! Job ${data.jobId} has been added to account ${data.accountId}`
      );
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
};

export const removeJobFromAccount = async data => {
  return await firestore
    .collection('users')
    .doc(`${data.userId}`)
    .collection('accounts')
    .doc(`${data.accountId}`)
    .update({
      jobs: firebase.firestore.FieldValue.arrayRemove(data.jobId)
    })
    .then(() => {
      console.log(
        `Success! Job ${data.jobId} has been removed from account ${data.accountId}`
      );
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
};

export const archiveJob = async data => {
  try {
    const batch = firestore.batch();
    //1. Get the job document reference
    const jobRef = firestore
      .collection('users')
      .doc(`${data.uid}`)
      .collection('jobs')
      .doc(`${data.id}`);

    //2. Create a archive job reference preserving job ID
    const archivedJobsRef = firestore
      .collection('users')
      .doc(`${data.uid}`)
      .collection('archived_jobs')
      .doc(`${data.id}`);
    //3.Copy job field data to new location
    batch.set(archivedJobsRef, data);

    //4. Get quotes colection reference for the job
    const quoteDocuments = await firestore
      .collection('users')
      .doc(`${data.uid}`)
      .collection('jobs')
      .doc(`${data.id}`)
      .collection('quotes')
      .get();

    //5. Loop through each quote document
    quoteDocuments.forEach(async doc => {
      console.log(doc);
      console.log(doc);
      const quoteRef = firestore
        .collection('users')
        .doc(`${data.uid}`)
        .collection('archived_jobs')
        .doc(`${data.id}`)
        .collection('quotes')
        .doc(doc.get('quoteID'));

      //6. Copy each quote to new location
      batch.set(quoteRef, doc.data());
      //7. Delete quote data from quote collection
      batch.delete(doc.ref);
    });

    //8. Delete job document
    batch.delete(jobRef);

    //9. Finally, commit database changes
    batch.commit();
    return true;
  } catch (err) {
    console.log(`Failed to archive job. ${err}`);
    return false;
  }
};

export const getJob = async data => {
  try {
    const docRef = firestore
      .collection('users')
      .doc(data.uid)
      .collection('jobs')
      .doc(data.jobId);
    return await docRef.get().then(doc => {
      if (doc.exists) {
        const documentData = doc.data();
        return documentData;
      }
      return null;
    });
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const duplicateJob = async data => {
  const duplicateJobReq = functions.httpsCallable('cloneJob');

  return duplicateJobReq({
    jobID: data.jobID
  })
    .then(res => {
      console.log(res);
      if (!res) throw new Error('Something went wrong with the server');
      else if (res && res.data && res.data.error)
        throw new Error('Could not duplicate job');
      else return res.data;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
};
