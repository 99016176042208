import React, { createContext, useEffect, useState, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';
import 'firebase/database';
import { createUserDocument, updateUserDocument } from 'src/hooks/user';
import { firestore } from 'src/firebase';
import { sendSignUpVerificationEmail } from 'src/hooks/management/customEmail';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isAdmin: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, isAdmin, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isAdmin,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [firebaseUser, setFirebaseUser] = useState(); //for firebase user

  const signInWithEmailAndPassword = async (email, password) => {
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    return response;
  };

  const signInWithCustomToken = async token => {
    const response = await firebase.auth().signInWithCustomToken(token);
    return response;
  };

  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    const response = await firebase.auth().signInWithPopup(provider);
    if (response.user && response.additionalUserInfo.isNewUser) {
      //create document in firestore
      await createUserDocument(response.user);
      //send verification email
      sendEmailVerification(response.user);
    }
    return response;
  };

  const signInWithXero = async (userRecord) => {
    
    if(userRecord && userRecord.idToken) {
      const result = await firebase.auth().signInWithCustomToken(userRecord.idToken)
      .catch(err => {
        console.log("xero", err);
        return { err: err };
      });
  
      if (result.user && userRecord.isNewUser) {
        //create document in firestore
        await createUserDocument(result.user);
      }
    }
    
    await updateUserDocument({
      uid: userRecord.uid,
      active_tenant: userRecord.active_tenant,
      token_set: userRecord.token_set
    })
    
    return;
  };
  
  const signInWithXeroRedirect = async () => {
    console.log("signInWithXeroRedirect");
    const provider = new firebase.auth.OAuthProvider('oidc.xero');
    provider.addScope('offline_access');
    provider.addScope('openid');
    provider.addScope('profile');
    provider.addScope('email');
    provider.addScope('accounting.transactions');
    
    const result = await firebase.auth().signInWithRedirect(provider)
    .catch(err => {
      console.log("xero redirect", err);
      return { err: err };
    });

    console.log("xero redirect", result);

    return result;
    
  };
  
  const xeroRedirectResult = async () => {
    
    const result = await firebase.auth().getRedirectResult()
    .catch(err => {
      console.log("xero login error", err);
      return { err: err };
    });

    console.log("xero login ", result);

    return result;
  };

  const sendEmailVerification = async user => {
    console.log(user);
    return await sendSignUpVerificationEmail({ email: user.email })
      .then(() => {
        return true;
      })
      .catch(err => {
        //usually error => 'auth/too-many-requests'
        return { err: err };
      });
  };

  const createUserWithEmailAndPassword = async (
    email,
    password,
    fname,
    lname
  ) => {
    const response = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    if (response.user) {
      var newUser = response.user;
      newUser.updateProfile({
        displayName: fname + ' ' + lname,
        name: fname + ' ' + lname
      });
      newUser['firstName'] = fname;
      newUser['lastName'] = lname;
      createUserDocument(newUser);
      sendEmailVerification(response.user);
    }
    return response;
  };

  const signInWithPhone = async (phone, recaptchaVerifier) => {
    return await firebase
      .auth()
      .signInWithPhoneNumber(phone, recaptchaVerifier)
      .then(confirmationResult => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        return confirmationResult;
      })
      .catch(error => {
        // Error; SMS not sent
        console.log(error);
      });
  };

  const confirmPhoneVerificationCode = async (confirmationResult, code) => {
    return await confirmationResult.confirm(code).then(async result => {
      // User signed in successfully.
      console.log(result);
      // if(result.additionalUserInfo.isNewUser){
      // await createUserDocument(result.user);
      // }
      return result;
    });
  };

  const linkWithEmailCredential = (email, password) => {
    var credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    firebase
      .auth()
      .currentUser.linkWithCredential(credential)
      .then(usercred => {
        var user = usercred.user;
        console.log('Account linking success', user);
        updateUserDocument({ uid: user.uid, email: email });
      })
      .catch(error => {
        console.log('Account linking error', error);
      });
  };

  const logout = () => {
    const user = firebase.auth().currentUser
    if (user && user.uid) {
      updateUserDocument({
        uid: user.uid,
        token_set: null
      }).then(() => {
        return firebase.auth().signOut();
      })
      
    }
  };

  const updateEmail = (email, currentPassword) => {
    return reauthenticate(currentPassword).then(() => {
      return firebaseUser.updateEmail(email);
    });
  };

  const updateUserEmail = email => {
    if (state.user) state.user.email = email;
  };

  const updatePassword = (newPassword, currentPassword) => {
    return reauthenticate(currentPassword).then(() => {
      return firebaseUser.updatePassword(newPassword);
    });
  };

  const sendPasswordResetEmail = async email => {
    return await firebase.auth().sendPasswordResetEmail(email);
  };

  const reauthenticate = currentPassword => {
    const credentials = firebase.auth.EmailAuthProvider.credential(
      firebaseUser.email,
      currentPassword
    );
    return firebaseUser.reauthenticateWithCredential(credentials);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      let callback = null;
      let metadataRef = null;

      if (callback) {
        metadataRef.off('value', callback);
      }

      if (user) {
        setFirebaseUser(user);
        metadataRef = firebase
          .database()
          .ref('metadata/' + user.uid + '/refreshTime');
        callback = snapshot => {
          user.getIdToken(true);
        };
        metadataRef.on('value', callback);
        const userDoc = firestore.collection('users').doc(user.uid);
        userDoc.get().then(doc => {
          if (doc.exists) {
            var userData = doc.data();
            user.updateProfile({
              displayName: userData.firstName
                ? userData.firstName + ' ' + userData.lastName
                : null
            });
            updateUserDocument({
              uid: user.uid,
              email: user.email,
              phone: user.phoneNumber,
              lastLogin: user.metadata.lastSignInTime
              // isNewUser: false
            });
          }
        });

        const token = await user.getIdTokenResult(true);
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: true,
            isAdmin: token.claims.isAdmin,
            user: {
              id: user.uid,
              avatar: user.photoURL,
              email: user.email,
              name: user.displayName || user.email || user.phoneNumber,
              tier: token.claims.subscriptionLevel || null,
              credits: token.claims.credits || null,
              isAdmin: token.claims.isAdmin
            }
          }
        });
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            isAdmin: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        firebaseUser,
        method: 'FirebaseAuth',
        confirmPhoneVerificationCode,
        createUserWithEmailAndPassword,
        linkWithEmailCredential,
        sendEmailVerification,
        sendPasswordResetEmail,
        signInWithCustomToken,
        signInWithEmailAndPassword,
        signInWithGoogle,
        signInWithPhone,
        logout,
        updateEmail,
        updatePassword,
        updateUserEmail,
        signInWithXero,
        signInWithXeroRedirect,
        xeroRedirectResult
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
