import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
 // Avatar,
 // Button,
//  Box,
//  Container,
//  Grid,
 // Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 0,
    paddingBottom: 0
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

//const swhiteImage = require('./images/swhite.png');
const Features = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      


      <footer>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="ftr_content text-center text-md-left mb-2 mb-md-0">
                       {/* <img src={swhiteImage} alt="" /> */} 
                        
                        <img src={'/static/swhite.png'}  alt=""/>
                        <span>QuotingCentral</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="ftr_content text-center text-md-left">
                        <a href="mailto:info@quotingcentral.com"><i class="fa fa-envelope"></i> info@quotingcentral.com</a>
                        <a href="https://www.facebook.com/QuotingCentral" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook"></i> Facebook</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>


    </div>
  );
};

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
