import { createSlice, current } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import objFromArray from 'src/utils/objFromArray';
import { getDefaultTask } from 'src/hooks/quote/tasks/defaultTask';
import constants from 'src/utils/constants';

const initialState = {
  quote: {
    tasks: [],
    firebase: true,
    jobId: '',
    quoteID: '',
    quoteName_t: '',
    quoteStatus_t: 'Draft'
  }
};

const slice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    initialiseQuote(state, action) {
      //state = quote = {tasks:[], ...}
      const { data } = action.payload;
      state.quote = { ...data };
      return state;
    },
    addDefaultTask(state, action) {
      const { task } = action.payload;

      if (!state.quote.tasks) {
        state.quote.tasks = [];
      }
      state.quote.tasks.push(task);
    },
    deleteTask(state, action) {
      const { taskId } = action.payload;
      state.quote.tasks = state.quote.tasks.filter(item => item.id != taskId);
    },
    updateTasks(state, action) {
      const { tasks } = action.payload;
      state.quote.tasks = tasks;
    },
    updateSingleTask(state, action) {
      const { task, taskId } = action.payload;
      const taskPos = state.quote.tasks.findIndex(
        _tasks => _tasks.id === taskId
      );
      state.quote.tasks[taskPos].data = task;
    },
    updateTask(state, action) {
      const { task, taskId } = action.payload;
      const taskPos = state.quote.tasks.findIndex(
        _tasks => _tasks.id === taskId
      );
      state.quote.tasks[taskPos] = task;
    },
    updateTaskName(state, action) {
      const { name, taskId } = action.payload;
      const taskPos = state.quote.tasks.findIndex(
        _tasks => _tasks.id === taskId
      );
      state.quote.tasks[taskPos].data.name = name;
      state.quote.tasks[taskPos].data.FullDescription = name;
    },
    updateTaskValue(state, action) {
      const { name, value, taskId } = action.payload;
      const taskPos = state.quote.tasks.findIndex(
        _tasks => _tasks.id === taskId
      );
      state.quote.tasks[taskPos].data[name] = value;
    },
    updateTaskCustomPrice(state, action) {
      const { data, taskId } = action.payload;
      const taskPos = state.quote.tasks.findIndex(
        _tasks => _tasks.id === taskId
      );
      var temp = state.quote.tasks[taskPos].data;
      state.quote.tasks[taskPos].data = {
        ...temp,
        price: data.price,
        quantity: data.quantity,
        UOM: data.unit,
        customPrice: true,
        lineItemLevel: true
      };
    },
    updateTaskConfiguratorInclusions(state, action) {
      const { value, taskId } = action.payload;
      const taskPos = state.quote.tasks.findIndex(
        _tasks => _tasks.id === taskId
      );

      if (taskPos != -1) {
        state.quote.tasks[taskPos].data['inclusions'] = value;
      }
    },
    updateTaskConfigurator(state, action) {
      const { arr, taskId, arrayName } = action.payload;
      const taskPos = state.quote.tasks.findIndex(
        _tasks => _tasks.id === taskId
      );

      if (taskPos != -1) {
        if (!state.quote.tasks[taskPos].data[arrayName]) {
          state.quote.tasks[taskPos].data[arrayName] = [];
        }
        const arrPos = state.quote.tasks[taskPos].data[arrayName].findIndex(
          _tasks => _tasks.id === arr.id
        );
        if (arrPos != -1) {
          state.quote.tasks[taskPos].data[arrayName][arrPos] = arr;
        } else {
          state.quote.tasks[taskPos].data[arrayName] = [
            ...state.quote.tasks[taskPos].data[arrayName],
            arr
          ];
        }

        //recalculate quote total
        const items = ['material', 'labour', 'other'];
        var total = 0;
        items.forEach(item => {
          const stateTemp = current(state);
          const temp = stateTemp.quote.tasks[taskPos].data[item];
          if (temp) {
            temp.map(arr => {
              if (arr && arr.total) {
                var intTotal = parseInt(arr.total);
                total += intTotal;
              }
            });
          }
        });
        if (total) {
          var temp = state.quote.tasks[taskPos].data;
          state.quote.tasks[taskPos].data = {
            ...temp,
            price: total,
            quantity: constants.DEFAULT_TASK_QUANTITY,
            customPrice: true,
            lineItemLevel: false
          };
        }
      }
    },
    deleteTaskConfiguratorItem(state, action) {
      const { arr, taskId, arrayName } = action.payload;
      const taskPos = state.quote.tasks.findIndex(
        _tasks => _tasks.id === taskId
      );

      if (taskPos != -1) {
        if (!state.quote.tasks[taskPos].data[arrayName]) return;
        state.quote.tasks[taskPos].data[arrayName] = state.quote.tasks[
          taskPos
        ].data[arrayName].filter(_tasks => _tasks.id !== arr.id);
      }
    },
    deleteTaskConfiguratorTag(state, action) {
      const { taskId, index } = action.payload;
      const taskPos = state.quote.tasks.findIndex(
        _tasks => _tasks.id === taskId
      );

      if (taskPos != -1) {
        if (!state.quote.tasks[taskPos].data['tags']) return;
        state.quote.tasks[taskPos].data['tags'] = state.quote.tasks[
          taskPos
        ].data['tags'].filter((a, i) => i !== index);
      }
    },
    updateSectionName(state, action) {
      const { sectionID, value } = action.payload;
      state.quote.tasks.map(_task => {
        if (_task.section === sectionID) {
          _task.sectionName = value;
        }
      });
    },
    updateDragEnd(state, action) {
      const { startIndex, endIndex } = action.payload;
      const result = Array.from(state.quote.tasks);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      result.forEach((item, index) => {
        item.priority = index;
      });
      state.quote.tasks = [...result];
    },
    deleteSection(state, action) {
      const { index } = action.payload;
      state.quote.tasks = state.quote.tasks.filter(
        item => item.section != index
      );
      state.quote.tasks.map(item => {
        if (item.section > index) {
          item.section = item.section - 1;
        }
      });
    },
    updateQuote(state, action) {
      const { valueArray } = action.payload;
      Object.entries(valueArray).map(
        ([key, value]) => (state.quote[key] = value)
      );
    }
  }
});

export const initialiseQuote = data => async dispatch => {
  dispatch(slice.actions.initialiseQuote({ data: data }));
};

export const addDefaultTask = () => async dispatch => {
  const defaultTask = getDefaultTask();
  await dispatch(slice.actions.addDefaultTask({ task: defaultTask }));
};

export const deleteTask = taskId => async dispatch => {
  await dispatch(slice.actions.deleteTask({ taskId: taskId }));
};

export const addTask = task => async dispatch => {
  dispatch(slice.actions.addDefaultTask({ task: task }));
};

export const updateTasks = tasks => async dispatch => {
  dispatch(slice.actions.updateTasks({ tasks: tasks }));
};

export const updateSingleTask = (task, taskId) => async dispatch => {
  dispatch(slice.actions.updateSingleTask({ task: task, taskId: taskId }));
};

export const updateTask = (task, taskId) => async dispatch => {
  dispatch(slice.actions.updateTask({ task: task, taskId: taskId }));
};

export const updateTaskName = (name, taskId) => async dispatch => {
  dispatch(slice.actions.updateTaskName({ name: name, taskId: taskId }));
};

export const updateTaskValue = (name, value, taskId) => async dispatch => {
  dispatch(
    slice.actions.updateTaskValue({ name: name, value: value, taskId: taskId })
  );
};

//data = row: {unit, unitPrice, quantity, customPrice:true}
export const updateTaskCustomPrice = (data, taskId) => async dispatch => {
  dispatch(slice.actions.updateTaskCustomPrice({ data: data, taskId: taskId }));
};

export const updateTaskConfiguratorInclusions = (
  value,
  taskId
) => async dispatch => {
  dispatch(
    slice.actions.updateTaskConfiguratorInclusions({
      value,
      taskId
    })
  );
};

export const updateTaskConfigurator = (
  arr,
  arrayName,
  taskId
) => async dispatch => {
  dispatch(
    slice.actions.updateTaskConfigurator({
      arr: arr,
      arrayName: arrayName,
      taskId: taskId
    })
  );
};

export const deleteTaskConfiguratorItem = (
  arr,
  arrayName,
  taskId
) => async dispatch => {
  dispatch(
    slice.actions.deleteTaskConfiguratorItem({
      arr: arr,
      arrayName: arrayName,
      taskId: taskId
    })
  );
};

export const deleteTaskConfiguratorTag = (index, taskId) => async dispatch => {
  dispatch(
    slice.actions.deleteTaskConfiguratorTag({
      index: index,
      taskId: taskId
    })
  );
};

export const updateSectionName = (sectionID, value) => async dispatch => {
  dispatch(
    slice.actions.updateSectionName({
      sectionID: sectionID,
      value: value
    })
  );
};

export const updateDragEnd = (startIndex, endIndex) => async dispatch => {
  dispatch(
    slice.actions.updateDragEnd({
      startIndex: startIndex,
      endIndex: endIndex
    })
  );
};

export const deleteSection = index => async dispatch => {
  dispatch(
    slice.actions.deleteSection({
      index: index
    })
  );
};

export const updateQuote = valueArray => async dispatch => {
  dispatch(
    slice.actions.updateQuote({
      valueArray: valueArray
    })
  );
};

export default slice;
export const reducer = slice.reducer;
