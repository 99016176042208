import { firestore } from 'src/firebase';
import { functions } from 'src/firebase';


export const createUser = async data => {
  const createUser = functions.httpsCallable('createUser');
  const response = await createUser({email:data.email,password:data.password, phoneNumber:data.phoneNumber})
  return response;         
}

export const getUserByPhone = async data => {
  const getUserByPhone = functions.httpsCallable('getUserByPhone');
  const response = await getUserByPhone({phoneNumber:data.phoneNumber})
  return response;         
}

export const getUserByEmail = async data => {
  const getUserByEmail = functions.httpsCallable('getUserByEmail');
  const response = await getUserByEmail({email:data.email})
  return response;         
}

export const createUserDocument = async user => {
  // reference to the Firestore document
  const docRef = firestore.doc(`/users/${user.uid}`);

  // create user object
  const userProfile = {
    id: user.uid,
    email: user.email,
    phone:user.phoneNumber,
    acceptedTCs: true,
    isNewUser: true,
    //tier: 'Free Trial',
    creationTime: user.metadata.creationTime,
    firstName:user.firstName || '',
    lastName:user.lastName || ''
  };

  // write to Cloud Firestore
  return docRef.set(userProfile);
};

export const updateUserDocument = async user => {
  const docRef = firestore.doc(`/users/${user.uid}`);
  if (docRef) {
    return docRef.update(user).then(() => {
      return true;
    });
  } else {
    return false;
  }
};


export const getUserDocument = async (data) => {
  try {
    const docRef = firestore
      .collection('users')
      .doc(data.uid)
    return await docRef.get().then(doc => {
      if (doc.exists) {
        const documentData = doc.data();
        return documentData;
      }
      return null
    });

  } catch (err) {
    console.error(err);
    return null;
  }
}
