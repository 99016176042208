import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
 /* Box,
  Container,
  Grid,
  Typography,*/
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));
//const logo = require('./images/23329c_f90482c5f00f4f5da6693edf7e850a78_mv2.jpg');


const Hero = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      


      <section class="main" id="home">
        <div class="container">
            
            <div class="main_title">
                <h1>Create Quotes On The <span>Spot</span>.</h1>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="main_content_l">
                        <h5>Introducing</h5>
                        <h2>Quoting Central</h2>
                        <p>Smart and easy quoting that gets you and your team on the job quicker.</p>
                        <a href="/signup" class="btn btn-primary">Start Free Trial Now</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="main_content">
                        
                        {/*<img src={logo} alt="quotingcentral"/> */}
                        <img src={'/static/23329c_f90482c5f00f4f5da6693edf7e850a78_mv2.jpg'}  alt="quotingcentral"/>
                    </div>
                </div>
            </div>

        </div>
    </section>




    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
