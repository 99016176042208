import { firestore } from 'src/firebase';

export const getMasterData = async () => {
  try {
    const snapshot = await firestore.collection('categories').get();
    const documents = {};
    snapshot.forEach(doc => {
      documents[doc.id] = doc.data();
    });
    return documents;
  } catch (err) {
    console.error(err);
    return null;
  }
};
