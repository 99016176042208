import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import firebase from 'firebase/app';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import clsx from 'clsx';
import WarningIcon from '@material-ui/icons/Warning';
import ClearIcon from '@material-ui/icons/Clear';
import { sendVerificationEmail } from 'src/hooks/management/customEmail';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFF4E5',
    padding: 1,
    display: 'flex',
    alignItems: 'center'
  },
  warningIcon: {
    color: 'black',
    fontSize: '22px'
  },
  warningMessage: {
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFF4E5'
  },
  cancelIcon: {
    right: 0
  },
  messageIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  }
}));

const UnverifiedEmail = ({ className, setEmailVerified }) => {
  const classes = useStyles();
  // const { sendEmailVerification } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [emailSend, setEmailSend] = useState(false);

  const handleSendEmailVerification = () => {
    try {
      setEmailSend(true);
      console.log(firebase.auth().currentUser)
      sendVerificationEmail({email: firebase.auth().currentUser.email}).then(response => {
        if (response.err) {
          console.log(response.err);
          enqueueSnackbar(
            'Unable to send verification email. Please try again later.',
            {
              variant: 'error'
            }
          );
        } else {
          enqueueSnackbar('Successfully sent verification email', {
            variant: 'success'
          });
        }
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        'Unable to send verification email. Please try again later.',
        {
          variant: 'error'
        }
      );
    }
  };

  useEffect(() => {
    try {
      const interval = setInterval(() => {
        //1. Check if user has logged in
        /**
         * This is because setInterval continues regardless of whether TopBar is rendered or not
         * and hence throws an exception when user logs out
         **/
        if (!firebase.auth().currentUser) {
          //if no user, cancel the call
          clearInterval(interval);
        } else {
          //continue checking if user has verified or not
          firebase
            .auth()
            .currentUser.reload()
            .then(() => {
              if (
                firebase.auth().currentUser &&
                firebase.auth().currentUser.emailVerified
              ) {
                console.log('Email verified');
                clearInterval(interval);

                //trigger display change in DashboardLayout
                if (setEmailVerified) {
                  setEmailVerified();
                }
              }
            });
        }
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  }, [setEmailVerified]);

  return (
    <Box width="100%" className={clsx(classes.root, className)}>
      <Grid
        container
        direction="row"
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        spacing={1}
      >
        <Grid item className={classes.messageIcon} sm={7}>
          <Box mr={2}>
            <WarningIcon className={classes.warningIcon} fontSize="small" />
          </Box>
          <Box mr={2}>
            <Typography
              component={'span'}
              variant="body1"
              className={classes.warningMessage}
            >
              {emailSend
                ? '  Check your inbox for confirmation'
                : 'Please check your email to verify your account and unlock all features.'}
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Button onClick={handleSendEmailVerification}>
            RESEND VERIFICATION EMAIL
          </Button>
        </Grid>
        <Grid item sm={1}>
          <ClearIcon onClick={setEmailVerified} style={{ fill: 'black' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UnverifiedEmail;
