import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText
  // Link
} from '@material-ui/core';
// import { Link as RouterLink } from 'react-router-dom';
import { NavLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import * as React from 'react';
import { useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  list: {
    width: 250
  },
  linkText: {
    textDecoration: `none`,
    //textTransform: `uppercase`,
    color: `black`
  },
  activeNav: {
    fontWeight: 'bold',
    color: 'red',
    borderBottom: '2px solid black'
  }
});

const SideDrawer = ({ navLinks }) => {
  const classes = useStyles();
  const [state, setState] = useState({ right: false });
  const history = useHistory();
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ [anchor]: open });
  };

  const sideDrawerList = anchor => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List component="nav">
        {navLinks.map(({ title, path, admin, logout }, index) =>
          logout ? (
            <ListItem button>
              <ListItemText primary={title} onClick={handleLogout} />
            </ListItem>
          ) : (
            (!admin || admin === user.isAdmin) && (
              <NavLink
                key={index}
                variant="body1"
                color="inherit"
                className={classes.linkText}
                to={path}
                activeClassName={classes.activeNav}
              >
                <ListItem button>
                  <ListItemText primary={title} />
                </ListItem>
              </NavLink>
            )
          )
        )}
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <IconButton
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer('right', true)}
      >
        <Menu fontSize="large" style={{ color: `black` }} />
      </IconButton>

      <Drawer
        anchor="right"
        open={state.right}
        onOpen={toggleDrawer('right', true)}
        onClose={toggleDrawer('right', false)}
      >
        {sideDrawerList('right')}
      </Drawer>
    </React.Fragment>
  );
};

export default SideDrawer;
