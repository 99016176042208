import firebase from "firebase/app"
import "firebase/auth"
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore/bundle';
import { connectFunctionsEmulator } from "firebase/functions";


const config = {
  apiKey: "AIzaSyCswmNBpqxngP-PkwqoV0T1VKzfN3JRX8w",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
}

//Without this check, it throws error "Firebase App named '[DEFAULT]' already exists (app/duplicate-app)"
const app = firebase.apps && firebase.apps.length > 0 ? firebase.apps[0] : firebase.initializeApp(config)

export const auth = app.auth()
export const firestore = firebase.firestore();
export const database = firebase.database();
export const functions = firebase.functions();
export const storage = firebase.storage();

if (process.env.NODE_ENV === "development") {
  // firebase.functions().useEmulator("localhost", 5001);
}



export default app

