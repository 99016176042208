import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { firestore } from 'src/firebase';
import { useHistory } from 'react-router-dom';
import { updateUserDocument } from 'src/hooks/user';

const GuestGuard = ({ children }) => {
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (user) {
      const docRef = firestore.collection('users').doc(user.id);
      const unsubscribe = docRef.onSnapshot(doc => {
        if (doc.exists) {
          const documentData = doc.data();
          const callbackJobUrl = localStorage.getItem("calling-job")
          localStorage.removeItem("calling-job")
          if (callbackJobUrl) {
            history.push(callbackJobUrl)
          } else if (documentData.isNewUser) {
            updateUserDocument({
              uid: documentData.id,
              isNewUser: false
            });
            history.push('/new-user/welcome');
          } else {
            history.push('/app/reports/dashboard');
          }
        }
      });
      return unsubscribe;
    }
  }, [user, history]);

  // if (isAuthenticated) {
  //   return <Redirect to="/app/reports/dashboard" />;
  // }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
