import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
 // Avatar,
 // Box,
 // Container,
 // Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 0,
    paddingBottom: 0
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

//const laptopImage = require('./images/laptop.png');

const Testimonials = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      


      <section class="section3" id="why">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="s3_content">
                        <h2> Less Admin, <br /> <span>More Me-Time</span></h2>
                        <p>Leave it on the job site. </p>
                        <p>No more taking unfinished quotes home.</p>
                        <p>Reduce admin, quote faster, land more jobs, spend more time with friends &amp; family.</p>
                        <br/>
                        <a href="/signup" class="btn btn-primary">Start Free Trial Now</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="s3_content_r">
                       {/* <img src={laptopImage} alt=""/>*/} 
                        <img src={'/static/laptop.png'}  alt="laptop"/>
                    </div>
                </div>
            </div>
        </div>
    </section>





    </div>
  );
};

Testimonials.propTypes = {
  className: PropTypes.string
};

export default Testimonials;
