import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  shallowEqual as reduxShallowEqual
} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { ENABLE_REDUX_DEV_TOOLS } from 'src/constants';
import rootReducer from './rootReducer';
import { createStore } from 'redux';

const store = configureStore({
  reducer: rootReducer,
  devTools: ENABLE_REDUX_DEV_TOOLS
});

// const store = createStore(
//   rootReducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

export const useSelector = useReduxSelector;
export const shallowEqual = reduxShallowEqual;

export const useDispatch = () => useReduxDispatch();

export default store;
