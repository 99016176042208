import { useEffect } from 'react';

const useZohoScript = url => {
  useEffect(() => {
    window.$zoho = window.$zoho || {};
    $zoho.salesiq = $zoho.salesiq || {
      widgetcode:
        '947a8eb37b66f182e045e69ea391d7c03eb2e8bd9198115c99b0c624748c5e4e',
      values: {},
      ready: function() {}
    };
    // $zoho.salesiq.ready = function() {
    //   $zoho.salesiq.floatbutton.position('right');
    // };
    var d = document;
    var s = d.createElement('script');
    s.type = 'text/javascript';
    s.id = 'zsiqscript';
    s.defer = true;
    s.src = 'https://salesiq.zoho.com.au/widget';
    var t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);

    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};


export default useZohoScript;
