import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
 // Avatar,
 // Button,
//  Box,
//  Container,
//  Grid,
//  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 0,
    paddingBottom: 0
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

//const iphoneImage = require('./images/iphone.png');


const Features = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      
      <section class="section2" id="what">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 order-2 order-md-1">
                    <div class="s2_content_l">
                       {/* <img src={iphoneImage} alt="" />*/}
                       <img src={'/static/iphone.png'}  alt="iphone"/>

                    </div>
                </div>
                <div class="col-lg-6 col-md-6 order-1 order-lg-2">
                    <div class="s2_content">
                        <h2>Quote Faster</h2>
                        <ul class="fa-ul">
                            <li><i class="fa fa-long-arrow-alt-right fa-li"></i> Pre-Priced Material catalogues.</li>
                            <li><i class="fa fa-long-arrow-alt-right fa-li"></i> Labour estimation calculators.</li>
                            <li><i class="fa fa-long-arrow-alt-right fa-li"></i> Sleek &amp; easy to follow quoting flow.</li>
                            <li><i class="fa fa-long-arrow-alt-right fa-li"></i> Measure. Estimate. Quote. </li>
                        </ul>

                        <a href="/signup" class="btn btn-primary">Start Free Trial Now</a>

                    </div>
                </div>
            </div>
        </div>
    </section>


    </div>
  );
};

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
