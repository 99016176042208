import { createSlice, current } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import objFromArray from 'src/utils/objFromArray';
import { getQuote } from 'src/hooks/quote/quote';
import { getDefaultTask } from 'src/hooks/quote/tasks/defaultTask';
import { getMasterData } from 'src/hooks/quote/masterData';
import { subjectToFilters } from 'src/hooks/quote/constants';
import { checkFilesIncludedInQuote } from 'src/hooks/quote/attachment';

const initialState = {
  blob: null,
  options: {
    showDates: true,
    showLineItemPricing: true,
    showQuoteStatus: true,
    showMarkup: true,
    showInclusions: true,
    applyMarkup: true,
    includeGST: true,
    markup: {},
    subjectTo: {},
    logoURL: null,
    files: [],
    jobDesc:''
  },
  textEntry: {
    showCoverletter: true,
    coverletter: '',
    showTCs: true,
    tcs: '',
    showFooter: true,
    footer: ''
  },
  initialLoad: true
};

const slice = createSlice({
  name: 'pdf',
  initialState,
  reducers: {
    initialisePDF(state, action) {
      //state = quote = {tasks:[], ...}
      const { data } = action.payload;
      console.log('redux initialising');
      try {
        state.initialLoad = false;
        state.options.showDates =
          data.showDates !== undefined ? data.showDates : true;
        state.options.showLineItemPricing =
          data.showLineItemPricing !== undefined
            ? data.showLineItemPricing
            : true;
        state.options.showQuoteStatus =
          data.showQuoteStatus !== undefined ? data.showQuoteStatus : true;
        state.options.showMarkup =
          data.showMarkup !== undefined ? data.showMarkup : true;
          state.options.showInclusions =
          data.showInclusions !== undefined ? data.showInclusions : true;
        state.options.applyMarkup =
          data.applyMarkup !== undefined ? data.applyMarkup : true;
        state.options.includeGST =
          data.includeGST !== undefined ? data.includeGST : true;
        state.options.markup = data.markup || {};
        state.options.logoURL = data.logoURL || null;
        state.options.jobDesc = data.jobDesc || ''

        state.textEntry.showCoverletter =
          data.showCoverletter !== undefined ? data.showCoverletter : true;
        state.textEntry.showTCs =
          data.showTCs !== undefined ? data.showTCs : true;
        state.textEntry.showFooter =
          data.showFooter !== undefined ? data.showFooter : true;

        state.textEntry.coverletter = data.coverletter || '';
        state.textEntry.tcs = data.tcs || '';
        state.textEntry.footer = data.footer || '';

        var tempSubjectTo = {};
        subjectToFilters.map(item => {
          tempSubjectTo[item.value] =
            (data.subjectTo && data.subjectTo[item.value]) || false;
        });
        state.options.subjectTo = { ...tempSubjectTo };

        var includedFiles = checkFilesIncludedInQuote(
          data.storageFiles,
          data.attachments
        );
        state.options.files = [...includedFiles];

        // setSubjectTo({ ...tempSubjectTo });
      } catch (err) {
        console.log('ERRROR', err);
      }
      return state;
    },
    resetInitialValue(state) {
      state.initialLoad = true;
    },
    changeOptionsBooleanState(state, action) {
      const { name } = action.payload;
      state.options[name] = !state.options[name];
    },
    changeTextEntryBooleanState(state, action) {
      const { name } = action.payload;
      state.textEntry[name] = !state.textEntry[name];
    },
    updateSubjectTo(state, action) {
      const { arr } = action.payload;
      state.options.subjectTo = arr;
    },
    updateMarkup(state, action) {
      const { arr } = action.payload;
      state.options.markup = arr;
    },
    updateTextEntryValue(state, action) {
      const { name, value } = action.payload;
      state.textEntry[name] = value;
    },
    updateLogo(state, action) {
      const { url } = action.payload;
      state.options.logoURL = url;
    },
    reinitialiseFiles(state, action) {
      const { files, list } = action.payload;
      var includedFiles = checkFilesIncludedInQuote(files, list);
      state.options.files = [...includedFiles];
      console.log("redux reintialising", includedFiles, state.options.files)

    },
    changeAttachmentStatus(state, action) {
      const { fileName } = action.payload;
      console.log(fileName);
      const filePos = state.options.files.findIndex(
        _files => _files.name === fileName
      );
      if (filePos != -1) {
        state.options.files[filePos]['checked'] = !state.options.files[filePos][
          'checked'
        ];
      }
      state.options.files = [...state.options.files];
      console.log("redux reintialising", state.options.files)

    },
    addAttachment(state, action) {
      const { file } = action.payload;
      console.log(file)
      state.options.files.push(file);
    },
    changeJobDescription(state, action) {
      const { desc } = action.payload;
      state.options.jobDesc = desc;
    },
    updateBlob(state, action) {
      const { blob } = action.payload;
      state.blob = blob;
    }
  }
});

export const initialisePDF = data => async dispatch => {
  console.log('REDUX: initialising', data);
  dispatch(slice.actions.initialisePDF({ data: data }));
};

export const resetPDFValues = () => async dispatch => {
  dispatch(slice.actions.resetInitialValue());
};

export const changeOptionsBooleanState = name => async dispatch => {
  dispatch(slice.actions.changeOptionsBooleanState({ name: name }));
};

export const changeTextEntryBooleanState = name => async dispatch => {
  dispatch(slice.actions.changeTextEntryBooleanState({ name: name }));
};

export const updateSubjectTo = arr => async dispatch => {
  console.log('Updating quote subject to', arr);
  dispatch(slice.actions.updateSubjectTo({ arr: arr }));
};

export const updateMarkup = arr => async dispatch => {
  console.log('Updating markup', arr);
  dispatch(slice.actions.updateMarkup({ arr: arr }));
};

export const updateTextEntryValue = (name, value) => async dispatch => {
  //Remove invalid tags from value
  // value = value.replace(/<br>/g, "<br/>");
  // value = value.replace(/<br data-cke-filler="true">/g, "<br/>");
  console.log(value);
  dispatch(slice.actions.updateTextEntryValue({ name: name, value: value }));
};

export const updateLogo = url => async dispatch => {
  dispatch(slice.actions.updateLogo({ url: url }));
};

export const changeAttachmentStatus = fileName => async dispatch => {
  dispatch(slice.actions.changeAttachmentStatus({ fileName }));
};

export const reinitialiseFiles = (files, list) => async dispatch => {
  dispatch(slice.actions.reinitialiseFiles({ files:files, list:list }));
};

export const changeJobDescription = (desc) => async dispatch => {
  dispatch(slice.actions.changeJobDescription({ desc }));
};

export const updateBlob = (blob) => async dispatch => {
  dispatch(slice.actions.updateBlob({ blob }));
};

export default slice;
export const reducer = slice.reducer;
