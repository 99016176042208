import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  // Box,
  /* Button,
   Divider,*/
  // Toolbar,
  // Hidden,
  // Typography,
  Link,
  makeStyles
} from '@material-ui/core';
// import { APP_VERSION } from 'src/constants';
//import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >


      <nav class="navbar navbar-expand-lg bg-white navbar-light">
        <div class="container justifyContent-content-between">

          <a class="navbar-brand" href="/"><img src="/static/qc-logo.png" alt="QuotingCentral" /></a>

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <a class="nav-link hvr-underline-from-center" href="#home">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link hvr-underline-from-center" href="#what">What</a>
              </li>
              <li class="nav-item">
                <a class="nav-link hvr-underline-from-center" href="#why">Why</a>
              </li>


              <li class="nav-item d-block d-lg-none">
                <a class="nav-link btn btn-primary" href="/register">Sign Up</a>
              </li>
              <li class="nav-item d-block d-lg-none">
                <a class="nav-link btn btn-outline-primary" href="/login">Sign In</a>
              </li>
            </ul>
          </div>

          <div class=" d-none d-lg-block">
            <ul class="navbar-nav">
              {user ?
                <li class="nav-item">
                  <Link
                    class="nav-link btn btn-outline-primary"
                    color="textSecondary"
                    component={RouterLink}
                    to="/app"
                    variant="body2"
                  >
                    Dashboard
                  </Link>
                </li>
                :
                <>
                  <li class="nav-item">
                    <a class="nav-link btn btn-primary" href="/register">Sign Up</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link btn btn-outline-primary" href="/login">Sign In</a>
                  </li>
                </>
              }
            </ul>
          </div>

        </div>
      </nav>

      { /* <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo} />
        </RouterLink>
        <<Hidden mdDown>
          Typography
            variant="caption"
            color="textSecondary"
          >
            Version
            {' '}
            {APP_VERSION}
          </Typography>
         
        </Hidden> 
        <Box flexGrow={1} />
        
       
      </Toolbar>*/}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
